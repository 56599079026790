import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Btns from '../../components/btns/Btns';
import Loading from '../../components/loading/Loading';
import Footer from '../../components/footer/Footer';
import AppContext from '../../context/AppContext';
import pdfFile from './Odob Axloq kodeksi2.pdf';

export default function RulesEtika() {
    const [activeButton, setActiveButton] = useState(6);
    const { leng } = useContext(AppContext); 
    const [data, setData] = useState([]);    
    const [loading, setLoading] = useState(true); 

    const buttons = [
        { id: 1, name_uz: 'Universitet haqida', name_ru: 'Об Университете', name_en: 'About University', link: '/about' },
        { id: 2, name_uz: 'Fakultetlar', name_ru: 'Факультеты', name_en: 'Faculties', link: '/department' },
        { id: 3, name_uz: 'Markaz va boʻlimlar', name_ru: 'Центры и Отделы', name_en: 'Centers and other departments', link: '/departments' },
        { id: 4, name_uz: 'Rektorat', name_ru: 'Ректорат', name_en: 'University Rectorate', link: '/rektorat' },
        { id: 5, name_uz: 'Universitet ichki mehnat tartib qoidalari', name_ru: 'Кодекс внутреннего трудового порядка университета', name_en: 'BEHAVIORAL CODE', link: '/rules' },
        { id: 6, name_uz: 'Universitet odob-axloq qoidalari', name_ru: 'Кодекс поведения', name_en: 'BEHAVIORAL CODE', link: '/rulesEtika' },
        { id: 7, name_uz: 'Universitet nizomi', name_ru: 'Устав Университета', name_en: 'University charter', link: '/regulation' },
        { id: 8, name_uz: 'Universitet tashkiliy tuzilmasi', name_ru: 'Cтруктура Университета', name_en: 'Structure of the University', link: '/tashkiliytuzilmasi' }
    ];

    // API'dan ma'lumotlarni olish
    const getData = async () => {
        try {
            const response = await axios.get(`https://new.utu-ranch.uz/category/axloq-qoidasi/`);
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); 
        }
    };

    useEffect(() => {
        getData();
    }, []);

    // Loading holati
    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div className="rules">
                {/* Tugmalar komponenti */}
                <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />

                {/* API'dan olingan ma'lumotlarni chiqarish */}
                {/* <div>
                    {data.map((item, index) => (
                        <p key={index} className="rulesmaintext">
                            {item[`desc_${leng}`]} 
                        </p>
                    ))}
                </div> */}
                <div className='pdfOpener' style={{ margin: '20px' }}>
                    <iframe
                        src={pdfFile}
                        title="Odob Axloq Kodeksi"
                        className="responsiveIframe"
                        style={{
                            height: '1000px',
                            border: 'none',
                        }}
                    ></iframe>
                </div>
            </div>
            <Footer />
        </>
    );
}
