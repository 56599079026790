import { useState } from "react";
import Btns from "../../components/btns/Btns";
import image1 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0001.jpg'
import image2 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0002.jpg'
import image3 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0003.jpg'
import image4 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0004.jpg'
import image5 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0005.jpg'
import image6 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0006.jpg'
import image7 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0007.jpg'
import image8 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0008.jpg'
import image9 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0009.jpg'
import image10 from './images/янги Устав UTU_RANCH 15.11.23 (2)_page-0010.jpg'
import './regulation.css'
import Footer from "../../components/footer/Footer";


export default function Regulation() {

    // btn set
    const [activeButton, setActiveButton] = useState(7);

    const buttons = [
        {
            id: 1,
            name_uz: 'Universitet haqida',
            name_ru: 'Об Университете',
            name_en: 'About University',
            link: '/about',
        },
        {
            id: 2,
            name_uz: 'Fakultetlar',
            name_ru: 'Факультеты',
            name_en: 'Faculties',
            link: '/department',
        },
        {
            id: 3,
            name_uz: 'Markaz va boʻlimlar',
            name_ru: 'Центры и Отделы',
            name_en: 'Centers and other departments',
            link: '/departments',
        },
        {
            id: 4,
            name_uz: 'Rektorat',
            name_ru: 'Ректорат',
            name_en: 'University Rectorate',
            link: '/rektorat',
        },
        {
            id: 5,
            name_uz: 'Universitet ichki mehnat tartib qoidalar',
            name_ru: 'Кодекс внутреннего трудового порядка университета',
            name_en: 'BEHAVIORAL CODE',
            link: '/rules',
        },
        {
            id: 6,
            name_uz: 'Universitet odob-axloq qoidalari',
            name_ru: 'Кодекс поведения',
            name_en: 'BEHAVIORAL CODE',
            link: '/rulesEtika',
        },
        {
            id: 7,
            name_uz: 'Universitet nizomi',
            name_ru: 'Устав Университета',
            name_en: 'University charter',
            link: '/regulation',
        },
        {
            id: 8,
            name_uz: "Universitet tashkiliy tuzilmasi",
            name_ru: "Cтруктура Университета",
            name_en: "Structure of the University",
            link: "/tashkiliytuzilmasi"
        }
    ]

    return (
        <>
            <div className="ustav">
                <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
                <div className="ustavImgs">
                    <img src={image1} alt="" />
                    <img src={image2} alt="" />
                    <img src={image3} alt="" />
                    <img src={image4} alt="" />
                    <img src={image5} alt="" />
                    <img src={image6} alt="" />
                    <img src={image7} alt="" />
                    <img src={image8} alt="" />
                    <img src={image9} alt="" />
                    <img src={image10} alt="" />
                </div>
            </div>
            <Footer />
        </>
    )
}