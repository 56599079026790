import React, { useEffect, useRef } from 'react';
import './happyyear.css';

// Define sounds array
const soundsPath = 'https://raw.githubusercontent.com/bybelov/new-year-garland/master/audio/';
const sounds = Array.from({ length: 36 }, (_, i) => `${soundsPath}sound${i + 1}.mp3`);

// Define keyMap object
const keyMap = [
  'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9',
  'Digit0', 'Minus', 'Equal', 'KeyQ', 'KeyW', 'KeyE', 'KeyR', 'KeyT', 'KeyY', 'KeyU', 'KeyI',
  'KeyO', 'KeyP', 'BracketLeft', 'BracketRight', 'KeyA', 'KeyS', 'KeyD', 'KeyF', 'KeyG', 'KeyH',
  'KeyJ', 'KeyK', 'KeyL', 'Semicolon', 'Quote', 'Backslash', 'KeyZ', 'KeyX', 'KeyC', 'KeyV',
  'KeyB', 'KeyN', 'KeyM', 'Comma', 'Period', 'Slash'
].reduce((acc, key, idx) => ({ ...acc, [key]: idx }), {});

// Define Balls class
class Balls {
  constructor(context, buffer) {
    this.context = context;
    this.buffer = buffer;
  }

  setup() {
    this.gainNode = this.context.createGain();
    this.source = this.context.createBufferSource();
    this.source.buffer = this.buffer;
    this.source.connect(this.gainNode);
    this.gainNode.connect(this.context.destination);
    this.gainNode.gain.setValueAtTime(1, this.context.currentTime);
  }

  play() {
    this.setup();
    this.source.start(this.context.currentTime);
  }

  stop() {
    const stopTime = this.context.currentTime + 1;
    this.gainNode.gain.exponentialRampToValueAtTime(0.1, stopTime);
    this.source.stop(stopTime);
  }
}

// Define Buffer class
class Buffer {
  constructor(context, urls) {
    this.context = context;
    this.urls = urls;
    this.buffer = [];
  }

  loadSound(url, index) {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => 
        this.context.decodeAudioData(arrayBuffer, (decodedBuffer) => {
          this.buffer[index] = decodedBuffer;
        })
      )
      .catch((error) => console.error(`Error loading sound ${index}:`, error));
  }

  getBuffer() {
    this.urls.forEach((url, index) => this.loadSound(url, index));
  }

  getSound(index) {
    return this.buffer[index];
  }
}

export default function HappyYear() {
  const audioContext = useRef(null);
  const bufferRef = useRef(null);

  useEffect(() => {
    // Initialize Audio Context and Buffer
    audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    bufferRef.current = new Buffer(audioContext.current, sounds);
    bufferRef.current.getBuffer();

    // Add keyboard listeners
    const handleKeyDown = (event) => {
      const key = event.code;
      if (key in keyMap) {
        const index = keyMap[key];
        playBallSound(index);
        const ball = document.querySelector(`[data-note="${index}"]`);
        if (ball) toggleBounce(ball);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const playBallSound = (index) => {
    if (!audioContext.current || !bufferRef.current) return;
    const sound = bufferRef.current.getSound(index);
    const ballAudio = new Balls(audioContext.current, sound);
    ballAudio.play();
  };

  const toggleBounce = (element) => {
    if (!element.classList.contains('bounce')) {
      element.classList.add('bounce');
      setTimeout(() => {
        element.classList.remove('bounce');
        element.classList.add('bounce1');
        setTimeout(() => {
          element.classList.remove('bounce1');
          element.classList.add('bounce2');
          setTimeout(() => {
            element.classList.remove('bounce2');
            element.classList.add('bounce3');
            setTimeout(() => {
              element.classList.remove('bounce3');
            }, 300);
          }, 300);
        }, 300);
      }, 300);
    }
  };

  const handleMouseEnter = (event) => {
    const note = parseInt(event.currentTarget.getAttribute('data-note') || '0', 10);
    playBallSound(note);
    toggleBounce(event.currentTarget);
  };

  return (
    <div className="b-page_newyear">
      <div className="b-page__content">
        <i className="b-head-decor">
          {Array.from({ length: 7 }, (_, index) => (
            <i key={index} className={`b-head-decor__inner b-head-decor__inner_n${index + 1}`}>
              {Array.from({ length: 9 }, (_, i) => (
                <div
                  key={i}
                  className="b-ball b-ball_bounce"
                  data-note={index * 9 + i}
                  onMouseEnter={handleMouseEnter}
                >
                  <div className="b-ball__right"></div>
                  <div className="b-ball__i"></div>
                </div>
              ))}
            </i>
          ))}
        </i>
      </div>
    </div>
  );
}
