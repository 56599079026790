import { useContext, useEffect, useState } from 'react';
import Btns from '../../components/btns/Btns';
import itImg from '../../image/otdelImg/it.png';
import xImg from '../../image/otdelImg/xudoynazar.png';
import mediaImg from '../../image/otdelImg/media.png'
import './otdel.css'
import axios from 'axios';
import AppContext from '../../context/AppContext';
import Footer from '../../components/footer/Footer';

export default function Otdel() {
    // btn set
    const [activeButton, setActiveButton] = useState(3);

    const buttons = [
        {
            id: 1,
            name_uz: 'Universitet haqida',
            name_ru: 'Об Университете',
            name_en: 'About University',
            link: '/about',
        },
        {
            id: 2,
            name_uz: 'Fakultetlar',
            name_ru: 'Факультеты',
            name_en: 'Faculties',
            link: '/department',
        },
        {
            id: 3,
            name_uz: 'Markaz va boʻlimlar',
            name_ru: 'Центры и Отделы',
            name_en: 'Centers and other departments',
            link: '/departments',
        },
        {
            id: 4,
            name_uz: 'Rektorat',
            name_ru: 'Ректорат',
            name_en: 'University Rectorate',
            link: '/rektorat',
        },
        {
            id: 5,
            name_uz: 'Universitet ichki mehnat tartib qoidalar',
            name_ru: 'Кодекс внутреннего трудового порядка университета',
            name_en: 'BEHAVIORAL CODE',
            link: '/rules',
        },
        {
            id: 6,
            name_uz: 'Universitet odob-axloq qoidalari',
            name_ru: 'Кодекс поведения',
            name_en: 'BEHAVIORAL CODE',
            link: '/rulesEtika',
        },
        {
            id: 7,
            name_uz: 'Universitet nizomi',
            name_ru: 'Устав Университета',
            name_en: 'University charter',
            link: '/regulation',
        },
        {
            id: 8,
            name_uz: "Universitet tashkiliy tuzilmasi",
            name_ru: "Cтруктура Университета",
            name_en: "Structure of the University",
            link: "/tashkiliytuzilmasi"
        }
    ]

    const [otdels, setOtdels] = useState([])

    const getOtdel = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/category/otdellar/`);
        setOtdels(response.data)
    }

    useEffect(() => {
        getOtdel()
    }, [])

    const { leng } = useContext(AppContext);

    return (
        <>
            <div className="Otdels">
                <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
                <div className="Otdels_boxes">
                    {
                        otdels.map(otdel => (
                            <div className="Otdels_box">
                                <img src={otdel.image} alt="" />
                                <div className="Otdels_box_infos">
                                    <h2>{otdel[`otdel_name_${leng}`]}</h2>
                                    <br />
                                    <label htmlFor="">{leng == 'uz' ? "Bo'lim boshlig'i:" : leng == 'ru' ? "Начальник отдела:" : "Head of Department:"}</label>
                                    <p>{otdel[`name_${leng}`]}</p>
                                    <br />
                                    <div className="Otdels_box_infos_contect">
                                        <div>
                                            <label htmlFor="">{leng == 'uz' ? "Kontakt:" : leng == 'ru' ? "Контакты:" : "Contacts:"}</label>
                                            <p>{otdel.cantact}</p>
                                        </div>
                                        <div>
                                            <label htmlFor="">{leng == 'uz' ? "Email:" : leng == 'ru' ? "Электронная почта:" : ""}</label>
                                            <p>{otdel.email}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}