import React, { useContext } from 'react';
import garphLine from '../../image/studyIcons/garphLine.svg';
import monyIcon from '../../image/studyIcons/money.svg';
import manageUserIcon from '../../image/studyIcons/manageUser.svg';
import marketingIcon from '../../image/studyIcons/marketingIcon.svg';
import golobalIcon from '../../image/studyIcons/globalIcon.svg';
import headIcon from '../../image/studyIcons/miyaIcon.svg';
import CalIcon from '../../image/studyIcons/Calculate.svg';
import KiICon from '../../image/studyIcons/KiIcon.svg';
import KbiIcon from '../../image/studyIcons/KbIIcon.svg';
import SetIcon from '../../image/studyIcons/setIcon.svg';
import CarIcon from '../../image/studyIcons/carIcon.svg';
import BookIcon from '../../image/studyIcons/bookIcon.svg';
import BankIcon from '../../image/studyIcons/bankIcon.svg';
import enrgyIcon from '../../image/studyIcons/enrgyIcon.svg';
import masterSetIcon from '../../image/studyIcons/masterSet.svg';
import arrowBoxIcon from '../../image/studyIcons/arrowBoxIcon.svg';
import BollIcon from '../../image/studyIcons/bollIcon.svg';
import MenuBookIcon from '../../image/studyIcons/menuBook.svg';
import './napravleni.css'
import AppContext from '../../context/AppContext';

export default function Napravlenya() {
    const textYonalish = [
        {
            "code": "603.01",
            "ru": "Экономика",
            "en": "Economics",
            "uz": "Iqtisodiyot",
            "icon": garphLine
        },
        {
            "code": "6041030",
            "ru": "Бухгалтерский учёт и аудит",
            "en": "Accounting and Auditing",
            "uz": "Buxgalteriya hisobi va audit",
            "icon": monyIcon
        },
        {
            "code": "60310300",
            "ru": "Управление",
            "en": "Management",
            "uz": "Menejment",
            "icon": manageUserIcon
        },
        {
            "code": "603.02",
            "ru": "Маркетинг",
            "en": "Marketing",
            "uz": "Marketing",
            "icon": marketingIcon
        },
        {
            "code": null,
            "ru": "Русский язык в группах других языков",
            "en": "Russian Language in Other Language Groups",
            "uz": "O’zga tili guruhlarda rus tili",
            "icon": golobalIcon
        },
        {
            "code": null,
            "ru": "Психология и педагогика дошкольного образования",
            "en": "Preschool Education Psychology and Pedagogy",
            "uz": "Maktabgacha ta’lim psixologiyasi va pedagogikasi",
            "icon": headIcon
        },
        {
            "code": null,
            "ru": "Бухгалтерский учёт и аудит",
            "en": "Accounting and Auditing",
            "uz": "Buxgalteriya hisobi va audit",
            "icon": CalIcon
        },
        {
            "code": null,
            "ru": "Компьютерная инженерия",
            "en": "Computer Engineering",
            "uz": "Kompyuter injiniringi",
            "icon": KiICon
        },
        {
            "code": null,
            "ru": "Инжиниринг кибербезопасности",
            "en": "Cybersecurity Engineering",
            "uz": "Kibervafvsizlik injiniringi",
            "icon": KbiIcon
        },
        {
            "code": null,
            "ru": "Технология машиностроения, оборудование и автоматизация",
            "en": "Machine Building Technology, Manufacturing and Automation",
            "uz": "Mashinasozlik texnologiyasi, ishlab chiqarish va avtomatlashtirish",
            "icon": SetIcon
        },
        {
            "code": null,
            "ru": "Оборудование и технология машиностроения",
            "en": "Transport Vehicle Engineering",
            "uz": "Transport vositalari muhandisligi",
            "icon": CarIcon
        },
        {
            "code": null,
            "ru": "Начальное образование",
            "en": "Primary Education",
            "uz": "Boshlang’ich ta’lim",
            "icon": BookIcon
        },
        {
            "code": null,
            "ru": "Банковское дело и аудит",
            "en": "Banking and Auditing",
            "uz": "Bank ishi va auditi",
            "icon": BankIcon
        },
        {
            "code": null,
            "ru": "Электроэнергетика",
            "en": "Electric Power Engineering",
            "uz": "Elektr energetikasi",
            "icon": enrgyIcon
        },
        {
            "code": null,
            "ru": "Инженерия транспортных средств",
            "en": "Transport Vehicle Engineering",
            "uz": "Transport vositalari muhandisligi",
            "icon": masterSetIcon
        },
        {
            "code": null,
            "ru": "Логистика",
            "en": "Logistics",
            "uz": "Logistikasi",
            "icon": arrowBoxIcon
        },
        {
            "code": null,
            "ru": "Спортивная деятельность (бокс)",
            "en": "Sports Activity (Boxing)",
            "uz": "Sport faoliyati (boks)",
            "icon": BollIcon
        },
        {
            "code": null,
            "ru": "Педагогика и психология",
            "en": "Pedagogy and Psychology",
            "uz": "Pedagogika va psixologiya",
            "icon": MenuBookIcon
        }
    ];

    const { leng } = useContext(AppContext);

    return (
        <div className="napravleniya">
            <p className="study_title">{leng == 'uz' ? "TA’LIM YOʻNALISHLARI" : leng == 'ru' ? "НАПРАВЛЕНИЯ ОБУЧЕНИЯ" : "FIELDS OF STUDY" }</p>
            <div className="study_btnAnim_line2">
                {textYonalish.map((item, index) => (
                    <button key={index}>
                        <img src={item.icon} alt={item.ru} />
                        {item[leng]}
                    </button>
                ))}
            </div>
        </div>
    );
}
